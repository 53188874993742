export const roleData  = {
    "question1": [
        {"name": "q1","value": "strong agree","type": "radio"},
        {"name": "q1","value": "agree","type": "radio"},
        {"name": "q1","value": "somewhat agree","type": "radio"},
        {"name": "q1","value": "somewhat disagree","type": "radio"},
        {"name": "q1","value": "disagree","type": "radio"},
        {"name": "q1","value": "","type": "text"}
    ],
    "question2": [
        {"name": "q2","value": "strong agree","type": "radio"},
        {"name": "q2","value": "agree","type": "radio"},
        {"name": "q2","value": "somewhat agree","type": "radio"},
        {"name": "q2","value": "somewhat disagree","type": "radio"},
        {"name": "q2","value": "disagree","type": "radio"},
        {"name": "q2","value": "","type": "text"}
    ],
    "question3": [
        {"name": "q3","value": "strong agree","type": "radio"},
        {"name": "q3","value": "agree","type": "radio"},
        {"name": "q3","value": "somewhat agree","type": "radio"},
        {"name": "q3","value": "somewhat disagree","type": "radio"},
        {"name": "q3","value": "disagree","type": "radio"},
        {"name": "q3","value": "","type": "text"}
    ],
    "question4": [
        {"name": "q4","value": "strong agree","type": "radio"},
        {"name": "q4","value": "agree","type": "radio"},
        {"name": "q4","value": "somewhat agree","type": "radio"},
        {"name": "q4","value": "somewhat disagree","type": "radio"},
        {"name": "q4","value": "disagree","type": "radio"},
        {"name": "q4","value": "","type": "text"}
    ]
}


