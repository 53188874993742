import React from "react";
import "./foundation.css";

const Footer = () => {
  return (
    <div className="bottom">
      <p>Copyright © 2022 CloudAngles</p>
    </div>
  );
};

export default Footer;
